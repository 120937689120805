import React, { useState, useEffect } from "react";
import * as style from "./PartnersSection.module.scss";
import FlipNavigation from "../../components/FlipNavigation";
import DekLogo from "../../images/dekpol_partner_logo.png"

const PartnersSection = ({ content, id }) => {
  const [partner, setPartner] = useState(0);

  useEffect(() => {
    const slider = document.querySelector(`.${style.partnersSlider}`);
    const elem = document.querySelector(`.${style.partner}`);
    if (slider) {
      slider.style.transform = `translateX(${-elem.offsetWidth * partner}px)`;
    }
  }, [partner]);

  return (
    <div className={style.container} id={id}>
      <div className={style.dividerTmp}></div>
      <div className={style.contentContainer}>
        <div className={style.logos}>
          <div className={style.logo}>
             <img
              src={DekLogo}
              alt={content.text_content.name1}
              style={{width: '100%', maxWidth: '280px'}}
            />
          </div>
          <div className={style.logo}>
            <img
              src={content.text_content.logo2}
              alt={content.text_content.name2}
            />
          </div>
        </div>
        <div
          className={style.text}
          dangerouslySetInnerHTML={{ __html: content.text_content.text }}
        >
          {/* {content.text_content.text} */}
        </div>
      </div>
      {/* <div className={style.divider}></div>
      <div className={style.contentContainer}>
        <div className={style.title}>
          <h2>{content.title.text}</h2>
        </div>
        <div className={style.partners}>
          <div className={style.partnersSlider}>
            {content.partners_content.map((partner, id) => (
              <div className={style.partner} key={id}>
                <div className={style.logo}>
                  <img src={partner.logo} alt={partner.name}></img>
                </div>
                <div className={style.desc}>{partner.text}</div>
              </div>
            ))}
          </div>
          <div className={style.navContainer}>
            <FlipNavigation
              min={0}
              max={content.partners_content.length}
              setCounter={setPartner}
              counter={partner}
              showPagesCounter={false}
              firstLast={false}

            />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default PartnersSection;
