// extracted by mini-css-extract-plugin
export var centerDot = "HistoryLogSection-module--centerDot--0ca62";
export var container = "HistoryLogSection-module--container--8faeb";
export var contentContainer = "HistoryLogSection-module--contentContainer--32599";
export var counters = "HistoryLogSection-module--counters--33ded";
export var divider = "HistoryLogSection-module--divider--2be20";
export var event = "HistoryLogSection-module--event--755b6";
export var eventDisabled = "HistoryLogSection-module--eventDisabled--d894f";
export var events = "HistoryLogSection-module--events--0ce67";
export var eventsContainer = "HistoryLogSection-module--eventsContainer--ab491";
export var gray = "HistoryLogSection-module--gray--55c11";
export var marker = "HistoryLogSection-module--marker--a0d89";
export var mediaContainer = "HistoryLogSection-module--mediaContainer--ece6b";
export var navigationContainer = "HistoryLogSection-module--navigationContainer--874f2";
export var timeline = "HistoryLogSection-module--timeline--5ebad";
export var title = "HistoryLogSection-module--title--7e74c";