import React, { useState, useEffect } from "react";
import * as style from "./HistoryLogSection.module.scss";
import VideoGallery from "../../components/VideoGallery";
import FlipNavigation from "../../components/FlipNavigation";
import HistoryGallery from "../../components/HistoryGallery";

const HistoryLogSection = ({ content }) => {
  const [page, setPage] = useState(0);

  useEffect(() => {
    const body = document.querySelector("body");
    body.addEventListener("resize", resizeHistoryPanel);
    resizeHistoryPanel();
  }, [page]);

  const resizeHistoryPanel = () => {
    const slider = document.querySelector(`.${style.events}`);
    const elem = document.querySelector(`.${style.event}`);
    if (slider) {
      slider.style.transform = `translateX(${-elem.offsetWidth * page}px)`;
    }
  };

  return (
    <div className={style.container}>
      <div className={style.contentContainer}>
        <div className={style.title}>
          <h2>{content.title.text}</h2>
        </div>
        <div className={style.timeline}></div>
        <div className={style.eventsContainer}>
          <div
            className={style.events}
            // style={{transform: 'translateX(-100%)'}}
          >
            {content.text_content.map((event, id) => (
              <div
                className={
                  id < page
                    ? style.event
                    : id === page
                    ? style.event
                    : `${style.event} ${style.gray}`
                }
                key={id}
              >
                <span>{event.date}</span>
                <h3>{event.title}</h3>
                <p>{event.text}</p>
                <div className={style.marker}></div>
              </div>
            ))}
          </div>
          <div className={style.navigationContainer}>
            <FlipNavigation
              min={0}
              max={content.text_content.length}
              setCounter={setPage}
              counter={page}
              mirror={true}
              firstLast={true}
            />
          </div>
        </div>
      </div>
      <div className={style.mediaContainer}>
        <div className={style.centerDot}></div>
        {/* <VideoGallery /> */}
        {/* {console.log(page)} */}
        <HistoryGallery imgNum={page} setNum={setPage} maxNum={content.text_content.length} />
      </div>
    </div>
  );
};

export default HistoryLogSection;
