import React, { useState, useEffect } from "react";
import * as style from "./MapSection.module.scss";
import Counter from "../../components/Counter";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { HiZoomIn } from "react-icons/hi";
import GalleryModal from "../../components/GalleryModal";

import MapBg from "./MapBg";

const query = graphql`
  {
    allFile(
      filter: { relativeDirectory: { regex: "/galleries/mapImg/" } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        relativeDirectory
        childImageSharp {
          id
          gatsbyImageData(
            layout: FIXED
            placeholder: BLURRED
            formats: [AUTO,WEBP]
            height: 230            
          )
        }
      }
    }
  }
`;

const MapSection = ({ content }) => {
  const [fact, changeFact] = useState(content.map_content.points[0]);

  useEffect(() => {
    changeFact(content.map_content.points[0])
  }, [content])

  console.log(fact)
  const [activeFact, changeActiveFact] = useState(4);
  const imagesData = useStaticQuery(query).allFile.nodes;
  // const mapData = useStaticQuery(query).allFile.nodes;
  // const mapImage = getImage(
  //   mapData.filter((img) => img.name.includes(content.map_content.map))[0]
  // );

  const [factImg, changeFactImg] = useState("");
  const [showFullGallery, setShowFullGallery] = useState(false);

  useEffect(() => {
    // changeFactImg(
    //   getImage(imagesData.filter((img) => img.name.includes(fact.src))[0])
    // );
    console.log(activeFact);
  }, [activeFact]);

  return (
    <div className={style.container}>
      <div className={style.textContainer}>
        <div className={style.title}>
          <h2>{content.title.text}</h2>
        </div>

        <div
          className={style.paragraph}
          dangerouslySetInnerHTML={{ __html: content.text_content.text }}
        ></div>
        <div className={style.divider}></div>
        <div className={style.counters}>
          <Counter {...content.counter_content[0]} />
          <Counter {...content.counter_content[1]} />
        </div>
      </div>
      <div className={style.mapContainer}>
        <div className={style.map}>
          <MapBg name={content.map_content.map} />
          {/* <GatsbyImage
            image={mapImage}
            className={style.gatsbyImage}
            style={{
              position: "relative",
              width: "100%",
            }}
            imgStyle={{ objectFit: "contain" }}
            alt=""
          /> */}
          <div
            className={style.stacja}
            style={{
              left: content.map_content.stacja.left,
              top: content.map_content.stacja.top,
            }}
          >
            <a className={style.marker} href="#stacja">
              <div className={style.inner}></div>
            </a>
            <div className={style.label}>{content.map_content.stacja.text}</div>
          </div>
          {/* <div className={style.factImg}>
            <GatsbyImage
              image={factImg}
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
              }}
              alt=""
              imgStyle={{ objectFit: "cover" }}
            />
          </div> */}

          {content.map_content.points.map((point, id) => (
            <div
              key={id}
              className={
                activeFact === id
                  ? style.dot
                  : `${style.dot} ${style.dotUnActive}`
              }
              style={{
                position: "absolute",
                top: point.top,
                left: point.left,
              }}
              onClick={() => {
                changeFact(point);
                changeActiveFact(id);
              }}
            >
              <GatsbyImage
                // className={activeFact===id?style.imgDotActive:style.imgDot}
                className={style.imgDot}
                image={getImage(
                  imagesData.filter((img) => img.name.includes(point.src))[0]
                )}
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  // transform: "translate(-50%, -50%)"
                }}
                height="230"
                alt=""
                imgStyle={{ objectFit: "cover" }}
              />
              {/* <div
                className={style.overlay}
                onClick={() => setShowFullGallery(true)}
              >
                <HiZoomIn />
              </div> */}
            </div>
          ))}
        </div>
          <div style={{display:'flex', flexDirection:'row', alignItems: "center", justifyContent: 'flex-end', gap:'5px', marginTop: '20px', marginRight: '10px'}}>
            <div className={style.dotLegend}></div>{content.additional.clickForMore}
            </div>
        <div className={style.facts}>
          <div className={style.title}>{content.additional.funFact}</div>
          <div className={style.fact}>{fact.text}</div>
        </div>
      </div>
      {setShowFullGallery?
      <GalleryModal
      imagesData={imagesData}
      open={showFullGallery}
      setOpen={setShowFullGallery}
      initPos={activeFact}
      />:""
    }
    </div>
  );
};

export default MapSection;
