import React from "react";
import * as style from "./LanguageSelection.module.scss";

const LanguageSelection = ({ language, setLanguage }) => {
  return (
    <div className={style.container}>
      <div className={style.link} onClick={() => setLanguage("pl")}>
        <p style={language === "pl" ? { color: "var(--red-color)" } : {}}>PL</p>
        <div className={style.bar}></div>
      </div>
      <p>/</p>
      <div className={style.link} onClick={() => setLanguage("lt")}>
        <p style={language === "lt" ? { color: "var(--red-color)" } : {}}>LT</p>{" "}
        <div className={style.bar}></div>
      </div>
    </div>
  );
};

export default LanguageSelection;
