import React from "react";
import * as style from "./NavigationDots.module.scss";
import Scrollspy from "react-scrollspy";
import { useState } from "react";
import { useEffect } from "react";

const NavigationDots = ({ menu, active }) => {
  const navLinks = [...Object.values(menu.nav)];
  const navSections = [];
  navLinks.forEach((link) => {
    navSections.push(link.link);
  });

  const [activeLabel, setActiveLabel] = useState("");


  useEffect(() => {
    const label = activeLabel;
    if(label){
      label.style.setProperty("left", "17.5px");
      const timeout = setTimeout(() => {
        label.style.removeProperty("left");
        // activeLabel = "";
        clearTimeout(timeout);
      }, 3000);
    }
  }, [activeLabel]);
  const dispalyDescryption = async () => {
    setActiveLabel((prev) => {
      prev?.style?.removeProperty("left");
      return (document.querySelector(`.${style.active} .${style.label}`))
    });
  };

  return (
    <Scrollspy
      items={navSections}
      currentClassName={style.active}
      className={style.container}
      offset={-200}
      onUpdate={dispalyDescryption}
    >
      {navLinks.map((item, index) => (
        <a href={`#${item.link}`} key={index} className={style.hover}>
          <div
            className={style.dot}
            // style={
            //   active === item.link
            //     ? { backgroundColor: "var(--red-color)" }
            //     : {}
            // }
          ></div>
          <div className={style.label}>{item.text}</div>
        </a>
      ))}
    </Scrollspy>
  );
};

export default NavigationDots;
