import React, { useEffect, useState } from "react";
import HeroSection from "../container/Hero/HeroSection";
import "./index.css";
import {
  main as mainContent_lt,
  menu as menuContent_lt,
  footer as footerContent_lt,
} from "../content_lt.json";
import {
  main as mainContent_pl,
  menu as menuContent_pl,
  footer as footerContent_pl,
} from "../content.json";

// import styles from '../styles/styles.module.scss'
import MapSection from "../container/Map/MapSection";
import GallerySection from "../container/Gallery/GallerySection";
import PeopleSection from "../container/People/PeopleSection";
import HistoryLogSection from "../container/HistoryLog/HistoryLogSection";
import PartnersSection from "../container/Partners/PartnersSection";
import FooterSection from "../container/Footer/FooterSection";

import NavigationDots from "../components/NavigationDots";
import NavigationModal from "../components/NavigationModal";

const IndexPage = () => {
  const [language, setLanguage] = useState("pl");

  let mainContent = mainContent_pl;
  let menuContent = menuContent_pl;
  let footerContent = footerContent_pl;
  if (language === "lt") {
    mainContent = mainContent_lt;
    menuContent = menuContent_lt;
    footerContent = footerContent_lt;
  }

  useEffect(() => {
    //to prevent initial scroloing on reload
    const body = document.querySelector("body");
    const html = document.querySelector("html");
    body.scrollTop = 0;
    html.scrollTop = 0;
    window.onunload = function () {
      window.scrollTo(0, 0);
      body.scrollTop = 0;
      html.scrollTop = 0;
    };
  }, []);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if(urlParams.get('lang') === 'lt') {
            setLanguage('lt')
        }
    }, []);

  return (
    <main className="main">
      <div id="">
        <HeroSection
          content={mainContent.home.hero}
          menu={menuContent}
          language={language}
          setLanguage={setLanguage}
        ></HeroSection>
      </div>
      <div id="antarktyka">
        <MapSection content={mainContent.home.sections["1"]} />
      </div>
      <div id="stacja">
        <GallerySection
          content={mainContent.home.sections["2"]}
          // extra="image_5.png"
          extra=""
        />
        <GallerySection
          content={mainContent.home.sections["3"]}
          extra=""
          id=""
          variant="mirror"
          imgTag="Kuryłowicz & Associates"
        />
        <PeopleSection content={mainContent.home.sections["4"]} />
      </div>
      <div id="ekspedycja">
        <GallerySection
          stl={{
            backgroundColor: "var(--blue-color)",
            color: "var(--white-color)",
          }}
          content={mainContent.home.sections["5"]}
          extra=""
          id="ekspedycja"
          variant="mirror"
          extrasColor={{ color: "var(--white-color)" }}
        />
        <HistoryLogSection content={mainContent.home.sections["6"]} id="" />
        {/* <div id="partnerzy"> */}
        <PartnersSection content={mainContent.home.sections["7"]} />
        {/* </div> */}
      </div>
      <div id="kontakt">
        <FooterSection content={footerContent} menu={menuContent} />
      </div>
      <NavigationDots menu={menuContent} />
      <NavigationModal
        menu={menuContent}
        language={language}
        setLanguage={setLanguage}
      />
    </main>
  );
};

export default IndexPage;

export const Head = () => (
  <>
    <title>Antarktyka - Budownictwo na krańcu świata - Dekpol</title>
    <meta
      name="title"
      content="Antarktyka - Budownictwo na krańcu świata - Dekpol"
    />
    <meta
      name="description"
      content="Budowa stacji antarktycznej to prawdziwe wyzwanie, ale też sprawdzian kompetencji, doświadczenia i budowlanego charakteru. Realizacja projektu wymaga od zespołu Dekpol Budownictwo nie tylko użycia odpowiedniego sprzętu i technologii, ale przede wszystkim umiejętności, świetnej logistyki, solidarności i hartu ducha."
    />

    {/* Open Graph / Facebook */}
    <meta property="og:type" content="website" />
    <meta
      property="og:url"
      content="https://dekpol.pl/generalne-wykonawstwo/antarktyka/"
    />
    <meta
      property="og:title"
      content="Antarktyka - Budownictwo na krańcu świata - Dekpol"
    />
    <meta
      property="og:description"
      content="Budowa stacji antarktycznej to prawdziwe wyzwanie, ale też sprawdzian kompetencji, doświadczenia i budowlanego charakteru. Realizacja projektu wymaga od zespołu Dekpol Budownictwo nie tylko użycia odpowiedniego sprzętu i technologii, ale przede wszystkim umiejętności, świetnej logistyki, solidarności i hartu ducha."
    />
    <meta
      property="og:image"
      content="https://dekpol.pl/generalne-wykonawstwo/antarktyka/metaTag.jpg"
    />

    {/* Twitter */}
    <meta property="twitter:card" content="summary_large_image" />
    <meta
      property="twitter:url"
      content="https://dekpol.pl/generalne-wykonawstwo/antarktyka/"
    />
    <meta
      property="twitter:title"
      content="Antarktyka - Budownictwo na krańcu świata - Dekpol"
    />
    <meta
      property="twitter:description"
      content="Budowa stacji antarktycznej to prawdziwe wyzwanie, ale też sprawdzian kompetencji, doświadczenia i budowlanego charakteru. Realizacja projektu wymaga od zespołu Dekpol Budownictwo nie tylko użycia odpowiedniego sprzętu i technologii, ale przede wszystkim umiejętności, świetnej logistyki, solidarności i hartu ducha."
    />
    <meta
      property="twitter:image"
      content="https://dekpol.pl/generalne-wykonawstwo/antarktyka/metaTag.jpg"
    />

    <script>history.scrollRestoration = "manual"</script>
  </>
);
