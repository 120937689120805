// extracted by mini-css-extract-plugin
export var active = "HeroSection-module--active--a6826";
export var arrows = "HeroSection-module--arrows--be76a";
export var background = "HeroSection-module--background--ec106";
export var bar = "HeroSection-module--bar--f1a7a";
export var bigDot = "HeroSection-module--bigDot--3c2fd";
export var bottom = "HeroSection-module--bottom--1de69";
export var bottomNav = "HeroSection-module--bottomNav--5e8d3";
export var bottomText = "HeroSection-module--bottomText--b2bc6";
export var container = "HeroSection-module--container--4513e";
export var content = "HeroSection-module--content--510fe";
export var dots = "HeroSection-module--dots--ff0ca";
export var link = "HeroSection-module--link--e62e7";
export var logo = "HeroSection-module--logo--851cd";
export var navDot = "HeroSection-module--navDot--baffc";
export var navDotActive = "HeroSection-module--navDotActive--50537";
export var subtitle = "HeroSection-module--subtitle--44db0";
export var title = "HeroSection-module--title--e7b36";
export var top = "HeroSection-module--top--a6ff1";
export var topNav = "HeroSection-module--topNav--fdbdf";