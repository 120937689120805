import React  from "react";
import Counter from "../../components/Counter";
import Gallery from "../../components/Gallery";
import * as style from "./GallerySection.module.scss";

const GallerySection = ({
  stl,
  content,
  extra,
  id,
  variant = "",
  extrasColor = {},
  imgTag,
}) => {
  if (content?.type === "gallery")
    return (
      <div
        style={stl}
        className={
          variant === "mirror"
            ? `${style.containerMirror} ${style.container}`
            : style.container
        }
        id={id}
      >
        <div className={style.textColumn} style={extrasColor}>
          <h2 style={extrasColor}>{content.title.text}</h2>
          <h3 style={extrasColor}>{content.subtitle.text}</h3>
          <div
            className={style.paragraph}
            style={extrasColor}
            dangerouslySetInnerHTML={{ __html: content.text_content.text }}
          ></div>
          <div className={style.divider}></div>
          <div className={style.counters}>
            <Counter {...content.counter_content[0]} color={extrasColor} />
            <Counter {...content.counter_content[1]} color={extrasColor} />
          </div>
        </div>
        <div className={style.galleryColum}>
          <Gallery
            source={content.gallery_content.src}
            variant={variant}
            stl={stl}
            imgTag={imgTag}
            // images={content.gallery_content}  extra=''
          />
        </div>
        {extra ? (
          <div className={style.centerDot}>
            <img
              src={extra}
              alt=""
              style={{ width: "100%", height: "100%" }}
            ></img>
          </div>
        ) : (
          <div className={style.redDot}></div>
        )}
      </div>
    );
  return <>missing data</>;
};

export default GallerySection;
