import React, { useState } from "react";
import * as style from "./Counter.module.scss";
import CountUp from "react-countup";
import ScrollTriger from "react-scroll-trigger";

const Counter = ({
  initVal = "0",
  finalVal,
  units = "",
  descr = "",
  extra = "",
  extraNL= false,
  color = {},
}) => {
  const [counterOn, setCounterOn] = useState(false);

  return (
    <div className={style.container}>
      <div className={style.extra} style={color}>
      {!extraNL?
        extra:''
      }
      </div>
      <div className={style.value}>
        <ScrollTriger
          onEnter={() => setCounterOn(true)}
          onExit={() => setCounterOn(false)}
        >
          <p>      
            {
              extraNL?
              <span className={style.extra}>
{extra}
            </span>
        :''
      }
            {counterOn && (
              <CountUp
                start={initVal}
                end={finalVal}
                duration={2}
                style={color}
              />
            )}
            {/* {finalVal} */}
          </p>
        </ScrollTriger>
        <p className={style.units} style={color}>
          {units}
        </p>
      </div>
      <div className={style.descryption} style={color}>
        {descr}
      </div>
    </div>
  );
};

export default Counter;
