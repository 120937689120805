// extracted by mini-css-extract-plugin
export var container = "PartnersSection-module--container--cbeb9";
export var contentContainer = "PartnersSection-module--contentContainer--b2ba8";
export var desc = "PartnersSection-module--desc--7a538";
export var divider = "PartnersSection-module--divider--94133";
export var dividerTmp = "PartnersSection-module--dividerTmp--d19e0";
export var logo = "PartnersSection-module--logo--e429e";
export var logos = "PartnersSection-module--logos--5845a";
export var navContainer = "PartnersSection-module--navContainer--03b3d";
export var partner = "PartnersSection-module--partner--3bbd5";
export var partners = "PartnersSection-module--partners--b2d6d";
export var partnersSlider = "PartnersSection-module--partnersSlider--84840";
export var text = "PartnersSection-module--text--5d174";
export var title = "PartnersSection-module--title--1bb1d";