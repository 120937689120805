import React from "react";
import logo from "../../images/logo_footer2.png"
import * as style from "./FooterSection.module.scss";

const FooterSection = ({ menu, content, id }) => {
  const navLinks = [...Object.values(menu.nav)];

  return (
    <div id={id} className={style.container}>
      <div className={style.left}>
        <div className={style.logo}>
          <img src={logo} alt=""></img>
        </div>
        <div className={style.contactDetails}>
          <ul>
            {/* <li>
              <b>tel.</b> +48 231 321 321
            </li> */}
            <li>
              <b>mail:</b> <a href="mailto:antarktyka@dekpol.pl" style={{textDecoration: 'none', color: 'inherit'}}>antarktyka@dekpol.pl</a>
            </li>
          </ul>
        </div>
      </div>
      <div className={style.navLinks}>
        {navLinks.map((link, id) => (
          <a key={id} href={`#${link.link}`} >
            {link.text}
          </a>
        ))}
      </div>
      <div className={style.divider}></div>
      <div className={style.bottomContainer}>
        <div className={style.copyright}>{content.copyright}</div>
        <div className={style.additionalLinks}>
          {content.additional_links.map((link, id) => (
            <a key={id} href={link.link} target="_blank" rel="noreferrer">
              {link.text}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FooterSection;
