// extracted by mini-css-extract-plugin
export var arrows = "PeopleSection-module--arrows--b424a";
export var bigger = "PeopleSection-module--bigger--2f73e";
export var container = "PeopleSection-module--container--89238";
export var contentContainer = "PeopleSection-module--contentContainer--ed80f";
export var descryption = "PeopleSection-module--descryption--1f047";
export var descryptionContainer = "PeopleSection-module--descryptionContainer--b1217";
export var divider = "PeopleSection-module--divider--22f78";
export var navigation = "PeopleSection-module--navigation--4f607";
export var pages = "PeopleSection-module--pages--d9589";
export var photo = "PeopleSection-module--photo--63b19";
export var photoContainer = "PeopleSection-module--photoContainer--d5fc4";
export var redDot = "PeopleSection-module--redDot--77480";
export var shade = "PeopleSection-module--shade--43e86";
export var title = "PeopleSection-module--title--f80f8";