import React, { useEffect} from "react";

import { FaPlayCircle} from "react-icons/fa";
import video from "../images/galleries/video/video.mp4"


import * as style from "./VideoGallery.module.scss";



const Gallery = () => {

  const play=()=>{
    var video = document.getElementById("video");
    var playBtn = document.getElementById("playBtn");
    if(video.paused){
      video.play();
      playBtn.style.display='none';
    }
    else {
      video.pause();
      playBtn.style.display='block';
    }
  }

  return (
    <div className={style.container} style={{backgroundColor:"red"}}>
      
      
      <video onClick={()=>play()} id='video'  muted loop>
      <source src={video} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    <div onClick={()=>play()} id='playBtn' className={style.playContainer}>
      <div className={style.button}>
        <FaPlayCircle />
      </div>

    </div>
    </div>
  );
};

export default Gallery;
