// extracted by mini-css-extract-plugin
export var container = "MapSection-module--container--e2493";
export var counters = "MapSection-module--counters--c96ff";
export var divider = "MapSection-module--divider--e844f";
export var dot = "MapSection-module--dot--84c59";
export var dotActive_old = "MapSection-module--dotActive_old--fe1a0";
export var dotLegend = "MapSection-module--dotLegend--967d4";
export var dotUnActive = "MapSection-module--dotUnActive--d7a90";
export var fact = "MapSection-module--fact--0ed3b";
export var factImg = "MapSection-module--factImg--6d721";
export var facts = "MapSection-module--facts--9050b";
export var imgDot = "MapSection-module--imgDot--40ff9";
export var imgDotActive = "MapSection-module--imgDotActive--56f60";
export var inner = "MapSection-module--inner--30bf8";
export var label = "MapSection-module--label--a61f6";
export var map = "MapSection-module--map--1a205";
export var mapContainer = "MapSection-module--mapContainer--fa346";
export var marker = "MapSection-module--marker--4d312";
export var overlay = "MapSection-module--overlay--aca8e";
export var paragraph = "MapSection-module--paragraph--a469b";
export var stacja = "MapSection-module--stacja--57dc8";
export var textContainer = "MapSection-module--textContainer--735da";
export var title = "MapSection-module--title--8a5a6";