import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const mapQuery = graphql`
  {
    allFile(
      filter: { relativeDirectory: { regex: "/galleries/map/" } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        relativeDirectory
        childImageSharp {
          id
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: WEBP
          )
        }
      }
    }
  }
`;

const MapBg = ({name}) => {
  const mapData = useStaticQuery(mapQuery).allFile.nodes;
  const mapImage = getImage(
    mapData.filter((img) => img.name.includes(name))[0]
  );
  return (
    <GatsbyImage
      image={mapImage}
      //   className={style.gatsbyImage}
      style={{
          position: "relative",
          width: "100%",
        }}
        imgStyle={{ objectFit: "contain" }}
        alt=""
        />
        
  );
};

export default MapBg;
