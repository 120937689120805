import React, { useEffect, useState } from "react";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import GalleryModal from "./GalleryModal";
import ArrowLeft from '../svg/Icon-Arrow-Left.inline.svg';
import ArrowRight from '../svg/Icon-Arrow-Right.inline.svg';

import * as style from "./HistoryGallery.module.scss";

const query = graphql`
  {
    allFile(
      filter: { relativeDirectory: { regex: "/galleries/history/" } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        relativeDirectory
        childImageSharp {
          id
          gatsbyImageData(layout: FIXED, placeholder: BLURRED, formats: WEBP)
        }
      }
    }
  }
`;

const Gallery = ({ imgNum, setNum, maxNum }) => {
  const imagesData = useStaticQuery(query).allFile.nodes;
//   console.log(imagesData);
//   console.log(imgNum);
  return (
    <div className={style.container}>
      <div>
        <GatsbyImage
          image={getImage(imagesData[imgNum])}
          style={{
            position: "absolute",
            

            width: "100%",
            height: "100%",
            overflow: "hidden",
          }}
          imgStyle={{ objectFit: "cover"}}
          alt=""
        />
      </div>
      <div
        className={style.navigation}        >
        <div className={style.arrows}>
          <div onClick={() => setNum(prev=>prev===0?maxNum-1:prev-1)}>
            <ArrowLeft />
          </div>
          <div onClick={() => setNum(prev=>prev===maxNum-1?0:prev+1)}>
            <ArrowRight />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
