import React from "react";
import * as style from "./FlipNavigation.module.scss";
import {FaAngleLeft, FaAngleRight} from 'react-icons/fa'
import {CgPushChevronLeft, CgPushChevronRight, CgChevronLeft, CgChevronRight} from 'react-icons/cg'
import ArrowLeft from "../../../Litwa/dekpol-litwa/static/Icon-Arrow-Left.inline.svg";
import ArrowRight from "../../../Litwa/dekpol-litwa/static/Icon-Arrow-Right.inline.svg";
import ArrowLeftEnd from "../../../Litwa/dekpol-litwa/static/Icon-ArrowEnd-Left.inline.svg";
import ArrowRightEnd from "../../../Litwa/dekpol-litwa/static/Icon-ArrowEnd-Right.inline.svg";

const FlipNavigation = ({ min = 1, max = 1, setCounter, counter, mirror = "false", showPagesCounter="true", firstLast='false' }) => {
  
  const flipPage = (a) =>{
    console.log("flippin");
    setCounter((prevState) => {
        if (prevState + a > max - 1) return 0;
        if (prevState + a < min) return max - 1;
        return prevState + a;
      });
  }
  
    return (
    <div className={style.container} style={mirror?{flexDirection:'row-reverse'}:{}} >
      {showPagesCounter?
      <div className={style.pages}>
        <span>{counter + 1}</span>
        <span className={style.bigger}>|</span>
        <span>{max}</span>
      </div>:<></>
      }
      <div className={style.arrows}>
        {firstLast?
        <div onClick={() => setCounter(min)}>
          <ArrowLeftEnd />
        </div>:<></>
        }
        <div onClick={() => flipPage(-1)}>
          <ArrowLeft />
        </div>
        <div onClick={() => flipPage(1)}>
          <ArrowRight />
        </div>
        {firstLast?
        <div onClick={() => setCounter(max-1)}>
          <ArrowRightEnd />
        </div>:<></>
        }
      </div>
    </div>
  );
};

export default FlipNavigation;
