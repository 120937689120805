import React, { useState, useEffect } from "react";
import { FaBars, FaFacebookF } from "react-icons/fa";
import { HiX } from "react-icons/hi";
import * as style from "./NavigationModal.module.scss";

const NavigationModal = ({ menu, active, language, setLanguage }) => {
  const [open, setOpen] = useState(false);
  const navLinks = [...Object.values(menu.nav)];

  return (
    <>
      <div
        className={style.button}
        onClick={() => {
          setOpen(!open);
        }}
      >
        {open ? <HiX /> : <FaBars />}
      </div>
      <div className={open ? `${style.modal} ${style.open}` : style.modal}>
        <img src="logo_modal.png" alt="" />

        <div className={style.navList}>
          {navLinks.map((link) => (
            <a
              className={style.link}
              href={`#${link.link}`}
              key={link.link}
              onClick={() => setOpen(false)}
            >
              <p>{link.text}</p>
              <div
                className={style.bar}
                style={
                  active === link.link
                    ? { backgroundColor: "var(--red-color)" }
                    : {}
                }
              ></div>
            </a>
          ))}
          <div className={style.language}>
            <div className={style.link} onClick={() => setLanguage("pl")}>
              <p style={language === "pl" ? { color: "var(--red-color)" } : {}}>
                PL
              </p>
              <div className={style.bar}></div>
            </div>
            <p>/</p>
            <div className={style.link} onClick={() => setLanguage("lt")}>
              <p style={language === "lt" ? { color: "var(--red-color)" } : {}}>
                LT
              </p>{" "}
              <div className={style.bar}></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavigationModal;
