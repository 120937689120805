import React, { useEffect, useState } from "react";
import { HiX } from "react-icons/hi";
import Close from "../svg/Icon-Close.svg"
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as style from "./GalleryModal.module.scss";
import FlipNavigation from "./FlipNavigation";

const GalleryModal = ({ imagesData, open, setOpen, initPos=0 }) => {
  const [scrollPosition, setScrollPosition] = useState(initPos);
// console.log({initPos});
// console.log({scrollPosition});

useEffect(()=>{
setScrollPosition(initPos);
},[initPos])
  return (
    <>
      <div className={open ? `${style.modal} ${style.open}` : style.modal}>
        <div className={style.galleryContainer}>
          <div
            className={style.button}
            onClick={() => {
              setOpen(false);
            }}
          >
            {/* <HiX /> */}
            <Close />
          </div>
          <div className={style.navContainer}>
            <FlipNavigation
              min={0}
              max={imagesData.length}
              counter={scrollPosition}
              setCounter={setScrollPosition}
              showPagesCounter={false}
              firstLast={false}
            />
          </div>

          <div className={style.navList}>
            <GatsbyImage
              image={getImage(imagesData[scrollPosition])}
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                overflow: "hidden",
              }}
              imgStyle={{ objectFit: "contain" }}
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default GalleryModal;
